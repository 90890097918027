<template>
  <div>
    <app-redirect/>
  </div>
</template>

<script>
  import appRedirect from "@/pages/components/appRedirect";

	export default {
	  components:{appRedirect},
	  name: "src-pages-body-bia-detail-v1_mobile",
		data() {
			return {

      }
		}
	}

</script>
